<script>
  import DarkModeController from '../components/dark-mode-controller.svelte';
</script>

<div class="dark-control">
  <DarkModeController />
</div>

<nav class="navbar d-none d-md-block">
  <div class="navbar-content">
    <a href="#introduction">Introduction</a>
    <a href="#main-skills">Main Skills</a>
    <a href="#experience">Experience</a>
  </div>
</nav>

<style>
  .dark-control {
    position: fixed;
    top: 0.4em;
    right: 0.4em;
    z-index: 9999;
  }

  @media (max-width: 767px) {
    .dark-control {
      position: absolute;
    }
  }

  .navbar {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: inherit;
    z-index: 500;
  }

  div.navbar-content {
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    width: fit-content;
  }

  .navbar-content a {
    color: inherit;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: x-large;
  }
</style>
