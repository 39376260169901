<script>
  let systemDark =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (event) => {
      systemDark = event.matches;
    });

  function changeMode() {
    manualDark = !dark;
    auto = false;
    document.documentElement.setAttribute('dark-mode', dark ? 'dark' : 'light');
  }

  function autoMode() {
    auto = true;
  }

  let auto = true;
  let manualDark = systemDark;

  $: autoCheck = auto ? '&#10004;' : '';
  $: dark = auto ? systemDark : manualDark;
  $: document.documentElement.setAttribute(
    'dark-mode',
    dark ? 'dark' : 'light'
  );
  $: modeButtonName = dark ? 'Light' : 'Dark';
</script>

<div class="input-group input-group-sm mb-3">
  <button class="btn btn-outline-secondary" type="button" on:click={changeMode}>
    {modeButtonName} mode
  </button>
  {#if !auto}
    <button class="btn btn-outline-secondary" type="button" on:click={autoMode}>
      Auto
    </button>
  {/if}
</div>

<style>
  .btn:focus {
    outline: none;
    box-shadow: none;
  }
</style>
