<div class="card">
  <div class="card-body">
    <slot />
  </div>
</div>

<style>
  .card {
    height: 100%;
  }
</style>
