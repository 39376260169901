<!--suppress CommaExpressionJS -->
<script>
  import { onMount } from 'svelte';

  export let id = crypto.randomUUID();
  export let inverseColors = false;

  function isVisible(element) {
    return !!element.offsetParent;
  }

  let even = true;
  let innerWidth = 0;

  function updateEven() {
    function isVisible(element) {
      return !!element.offsetParent;
    }

    const sections = Array.from(
      document.getElementsByClassName('alternate-colors')
    ).filter(isVisible);

    even = sections.findIndex((e) => e.id === id) % 2 === 0;
  }

  onMount(updateEven);
  $: innerWidth, updateEven();

  let evenClass;
  $: evenClass = even ^ !inverseColors ? 'even' : 'odd';
</script>

<svelte:window bind:innerWidth />

<div class="alternate-colors {evenClass}" {id}>
  <slot />
</div>

<style>
  .alternate-colors.even {
      --background-color: var(--background-color-1);
      --card-background-color: var(--background-color-2);
  }
  .alternate-colors.odd {
      --background-color: var(--background-color-2);
      --card-background-color: var(--background-color-1);
  }
  .alternate-colors {
      background-color: var(--background-color);
  }
</style>
