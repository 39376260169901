<script>
  import Section from '../components/section.svelte';
  import Card from '../components/card.svelte';

  export let info = {};
</script>

<Section title="Introduction" id="introduction">
  <Card>
    <div class="row">
      <div class="col-3">
        <img
          src={info.picture}
          alt="Me in Pamukkale"
          class="rounded-circle"
          width="100%" />
      </div>
      <div class="col-9 introduction-text">
        {info.introduction}
      </div>
    </div>
  </Card>
</Section>

<style>
  .introduction-text {
    white-space: pre-wrap;
  }
</style>
