<script>
  import Section from '../components/section.svelte';
  import Skill from '../components/skill.svelte';

  export let info = {};

  let rows = splitInRows(info.mainSkills, 3);
</script>

<Section title="Main Skills" id="main-skills">
  {#each rows as row}
    <div class="row">
      {#each row as data}
        <div class="col-sm-12 col-lg-4">
          <Skill {data} />
        </div>
      {/each}
    </div>
  {/each}
</Section>
