<script>
  import AlternateColors from './alternate-colors.svelte';

  export let title = '';
  export let id = '';

  const emptyClass = title === '' ? 'd-none' : '';

  function isVisible(element) {
    return !!element.offsetParent;
  }
</script>

<AlternateColors>
  <div class="section" {id}>
    <h1 class="title {emptyClass}">{title}</h1>
    <div class="container-sm d-wrapper">
      <div class="row">
        <div class="col-12">
          <slot />
        </div>
      </div>
    </div>
  </div>
</AlternateColors>

<style>
  .section {
    padding: 2em 0;
    background-color: var(--background-color);
  }
  .title {
    margin-bottom: 0.5rem;
  }
</style>
