<script>
  import AlternateColors from '../components/alternate-colors.svelte';

  export let info = {};
  const currentYear = new Date().getFullYear();
</script>

<AlternateColors inverseColors="true">
  <div class="footer">
    <small>{info.name} {currentYear} © All Rights Reserved</small>
  </div>
</AlternateColors>

<style>
  .footer {
    margin: 0;
    text-align: center;
  }
</style>
