<div class="arrow bounce"></div>

<style>
  .arrow {
    position: absolute;
    top: -4em;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0iIzU4NmU3NSIgZD0iTTI5My43NTEsNDU1Ljg2OGMtMjAuMTgxLDIwLjE3OS01My4xNjUsMTkuOTEzLTczLjY3My0wLjU5NWwwLDBjLTIwLjUwOC0yMC41MDgtMjAuNzczLTUzLjQ5My0wLjU5NC03My42NzIgIGwxODkuOTk5LTE5MGMyMC4xNzgtMjAuMTc4LDUzLjE2NC0xOS45MTMsNzMuNjcyLDAuNTk1bDAsMGMyMC41MDgsMjAuNTA5LDIwLjc3Miw1My40OTIsMC41OTUsNzMuNjcxTDI5My43NTEsNDU1Ljg2OHoiLz4KPHBhdGggZmlsbD0iIzU4NmU3NSIgZD0iTTIyMC4yNDksNDU1Ljg2OGMyMC4xOCwyMC4xNzksNTMuMTY0LDE5LjkxMyw3My42NzItMC41OTVsMCwwYzIwLjUwOS0yMC41MDgsMjAuNzc0LTUzLjQ5MywwLjU5Ni03My42NzIgIGwtMTkwLTE5MGMtMjAuMTc4LTIwLjE3OC01My4xNjQtMTkuOTEzLTczLjY3MSwwLjU5NWwwLDBjLTIwLjUwOCwyMC41MDktMjAuNzcyLDUzLjQ5Mi0wLjU5NSw3My42NzFMMjIwLjI0OSw0NTUuODY4eiIvPgo8L3N2Zz4=);
    background-size: contain;
  }

  .bounce {
    -webkit-animation: myfirst 2s infinite; /* Chrome, Safari, Opera */
    animation: myfirst 2s infinite;
  }

  /* Chrome, Safari, Opera */
  @-webkit-keyframes myfirst {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0px);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  /* Standard syntax */
  @keyframes myfirst {
    0%,
    20%,
    60%,
    100% {
      transform: translateY(0px);
    }
    40% {
      transform: translateY(-30px);
    }
    80% {
      transform: translateY(-15px);
    }
  }
</style>
