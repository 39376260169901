<!--suppress CssUnusedSymbol -->
<script>
  export let alignSide = 'left';
  export let event;
  export let currentEvent = false;

  const currentEventClass = currentEvent ? 'current-event' : '';
</script>

<div class="card event {alignSide} {currentEventClass}">
  <div class="current-event-header" />
  <div class="card-body">
    <table class="event-content">
      <tr>
        <td class="logo left">
          <img class="rounded-circle" src={event.logo} alt="" />
        </td>
        <td>
          <div class="years">
            <small>{event.start} - {event.end || 'present'}</small>
          </div>
          <div class="title">
            <strong>{event.title}</strong>
          </div>
          <div class="title">
            @ {event.at}
          </div>
        </td>
        <td class="logo right">
          <img class="rounded-circle" src={event.logo} alt="" />
        </td>
      </tr>
    </table>
    <div class="description">
      {event.description || ''}
    </div>
  </div>
</div>

<style>
  .card-body {
    padding: 0.5em;
  }

  .event {
    vertical-align: top;
  }

  td.logo {
    width: 2em;
    vertical-align: middle;
  }

  .event-content {
    width: 100%;
    padding: 0;
  }

  .event-content td {
    padding: 0;
  }

  .years {
    padding: 0 0.5em;
  }

  .title {
    padding: 0 0.5em;
  }

  .description {
    padding: 1em 0 0 0;
  }
  @media (min-width: 992px) {
    .left .logo.right {
      display: none;
    }

    .right .logo.left {
      display: none;
    }

      .event.right {
          text-align: right;
      }

      .event.left {
          text-align: left;
      }

    .event {
      height: 100%;
    }

    .event.current-event {
      position: relative;
    }

    .event.current-event .current-event-header {
      position: absolute;
      background: linear-gradient(
        0deg,
        var(--card-background-color) 0%,
        var(--background-color) 100%
      );
      left: -1px;
      top: -2em;
      height: 2.5em;
      width: calc(100% + 2px);
      border-style: solid;
      border-width: 0 1px;
      border-color: rgba(0, 0, 0, 0.125);
    }

    .years {
      display: none;
    }

    .logo img {
      height: 2em;
      width: 2em;
    }
  }
  @media (max-width: 991px) {
    .event {
      height: fit-content;
    }

    .logo img {
      height: 3em;
      width: 3em;
    }

    .logo.right {
        display: none;
    }
  }
</style>
