<script>
  import Card from './card.svelte';
  import Icon from './icon.svelte';

  export let data = {};
</script>

<div class="skill">
  <Card>
    <div class="content">
      <Icon icon={data.icon} colored="true" />
      <h2>{data.title}</h2>
      <div class="description">
        {data.description}
      </div>
    </div>
  </Card>
</div>

<style>
  .skill {
    margin: 1em 0;
    height: 100%;
    padding: 1em 0;
  }
  .content {
    text-align: center;
  }
</style>
