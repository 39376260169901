<!--suppress CssUnresolvedCustomProperty, CssUnusedSymbol -->
<script>
  import Section from '../components/section.svelte';
  import Event from '../components/event.svelte';

  export let info = {};

  const birthYear = info.birthYear;
  const currentYear = new Date().getFullYear();

  function asYear(date) {
    try {
      return Number(date.slice(-4));
    } catch (e) {
      return undefined;
    }
  }

  function normalizeEvent(event) {
    return {
      ...event,
      endYear: asYear(event.end),
      startYear: asYear(event.start),
      side: event.side || 'right',
    };
  }

  const events = info.events.map(normalizeEvent);

  const eventsByType = [
    {
      title: 'Jobs',
      classType: 'always',
      events: events.filter((e) => e.type === 'job'),
    },
    {
      title: 'Education',
      classType: 'always',
      events: events.filter((e) => e.type === 'education'),
    },
    {
      title: 'Life',
      classType: 'table-only',
      events: events.filter((e) => e.type === 'life'),
    },
  ];

  const educationEvents = events.filter((e) => e.type === 'education');

  const LINES_PER_YEAR = 2;

  const years = Array.from(
    { length: currentYear - birthYear + 1 },
    (i, k) => currentYear - k
  );

  function calcEventStyle(event) {
    const endYear = event.endYear || currentYear;
    const rows = Math.max(1, endYear - event.startYear);
    const yearsAgo = currentYear - endYear - (rows === 1 ? 0.5 : 0);
    const vars = {
      yearsAgo,
      rows,
    };
    if (event.at === 'Freelance') console.log(event, vars);
    return Object.entries(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');
  }

  function isCurrentEvent(event) {
    return !event.endYear;
  }

  function calcAlignSide(event) {
    return event.side === 'left' ? 'right' : 'left';
  }
</script>

<Section id="experience">
  <h1 class="table-only">Life</h1>
  <div class="life-table" style="--years: {years.length}">
    {#each years as year, i}
      <div class="year" style="--index: {i}">{year}</div>
    {/each}
    {#each eventsByType as typeEvents}
      <h1 class={typeEvents.classType}>{typeEvents.title}</h1>
      {#each typeEvents.events as event}
        <div
          class="event-container {event.side} {typeEvents.classType}"
          style={calcEventStyle(event)}>
          <Event
            {event}
            currentEvent={isCurrentEvent(event)}
            alignSide={calcAlignSide(event)} />
        </div>
      {/each}
    {/each}
  </div>
</Section>

<style>
  @media (min-width: 992px) {
    .table-only {
      display: inherit;
    }
    .list-only {
      display: none;
    }
    .life-table h1 {
      display: none;
    }
    .life-table {
      width: 100%;
      position: relative;
      height: calc(5em * var(--years) + 3em);
    }
    .event-container {
      position: absolute;
      top: calc(0.75em + 2.5em + 5em * var(--yearsAgo));
      height: calc(5em * var(--rows));
      left: 0;
      width: calc(50% - 2.5em);
      padding: 0.5em 0;
    }
    .year {
      text-align: center;
      vertical-align: middle;
      position: absolute;
      width: 5em;
      height: 1.5em;
      top: calc(2.5em + 5em * var(--index));
      left: calc(50% - 2.5em);
    }
    .year:before {
      content: '';
      display: block;
      width: 1px;
      height: 3.5em;
      background: var(--base0);
      left: 50%;
      top: -3.5em;
      position: absolute;
    }
    .event-container.right {
      left: calc((50% + 2.5em));
    }
  }
  @media (max-width: 991px) {
    .event-container {
      margin: 1em 0;
    }
    .table-only {
      display: none;
    }
    .list-only {
      display: inherit;
    }
    .year {
      display: none;
    }
  }
</style>
