<!--suppress CssUnresolvedCustomProperty -->
<script>
  import Icon from './icon.svelte';

  export let info = {};

  const iconsWidthInEm = 5 * info.technologies.length;

  let innerWidth = window.innerWidth;
  let fontSize = parseFloat(
    getComputedStyle(document.querySelector('body'))['font-size']
  );

  let innerWidthInEm;
  let times;
  let iconsToShow;
  $: innerWidthInEm = innerWidth / fontSize;
  $: times = Math.ceil(innerWidthInEm / iconsWidthInEm) + 1;
  $: iconsToShow = Array(times)
    .fill(null)
    .reduce((a) => [...a, ...info.technologies], []);
</script>

<svelte:window bind:innerWidth />

<div
  class="marquee-container"
  style="--icons-width: {iconsWidthInEm}; --times: {times}">
  <div class="marquee">
    <div class="marquee-content">
      {#each iconsToShow as icon}
        {#if icon}
          <Icon {icon} />
        {/if}
      {/each}
    </div>
  </div>
</div>

<style>
  .marquee-container {
    overflow: hidden;
  }

  .marquee {
    animation: marquee 15s linear infinite;
    width: 100%;
  }

  .marquee-content {
    width: calc(var(--icons-width) * 1em * var(--times));
  }

  .logo a {
    fill: var(--base01);
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(var(--icons-width) * -1em));
    }
  }
</style>
