<script>
  import Technologies from '../components/technologies.svelte';
  import BouncingArrow from '../components/bouncing-arrow.svelte';

  export let info = {};

  let title = '';

  let init = 1000;
  const step = 80;
  [...info.title].forEach((c) =>
    setTimeout(() => (title += c), (init += step))
  );
</script>

<div class="cover">
  <div class="cover-info">
    <div class="name">
      {info.name}
    </div>
    <div class="title">
      <span>{title}</span>
      <span class="blinking">|</span>
    </div>
  </div>
</div>
<div class="technologies">
  <BouncingArrow />
  <Technologies {info} />
</div>

<style>
  .name,
  .title {
    padding: 1em;
  }

  .technologies {
    position: absolute;
    top: calc(100% - 7em);
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .cover {
    height: 100%;
    text-align: center;
    font-size: 200%;
  }

  .cover-info {
    position: relative;
    top: 50%;
    transform: translateY(calc(-50% - 3.5em));
    z-index: 1;
  }

  .blinking {
    animation: blinkingText 1.2s infinite;
  }

  @keyframes blinkingText {
    0% {
      color: inherit;
    }
    49% {
      color: inherit;
    }
    60% {
      color: transparent;
    }
    99% {
      color: transparent;
    }
    100% {
      color: inherit;
    }
  }
</style>
